<template>
  <div>
    <!-- ... 註冊 ... -->
    <div class="pup_container_form reg_step_1" v-if="registerDialog==1">
      <div class="pup_form_bg" @click="closeDialog(0)"></div>
      <div class="pup_form_container">
        <div class="pup_form_title">會員註冊</div>
        <div class="pup_form_scroll">
          <div class="form_input_container">
            <div class="form_input_title">學生姓名</div>
            <div class="form_input">
              <input placeholder v-model="registerData.studentName" type="text" />
            </div>
          </div>
          <div class="form_input_container">
            <div class="form_input_title">學生年級</div>
            <div class="form_input">
              <select v-model="registerData.grade" @change="setGrade">
                <option v-for="(item,i) in hsdGradeList" :value="item" :key="i">{{item}}</option>
              </select>
            </div>
          </div>
          <div class="form_input_container">
            <div class="form_input_title">聯絡手機</div>
            <div class="form_input">
              <input placeholder type="text" @input="matchTel" v-model="registerData.phoneNum" />
            </div>
            <div class="form_input_err_tip" v-if="regTel">請輸入正確的手機號</div>
            <div class="form_input_tip">送出資料後將簡訊發送驗證碼至聯絡手機。</div>
          </div>
          <div class="pup_form_tip">
            <input name type="checkbox" v-model="isAgreed" />我已詳述並同意好師到
            <a @click="secretId=1">服務條款</a>與
            <a @click="secretId=2">隱私權聲明</a>
          </div>
        </div>
        <img
          alt
          class="close_icon"
          @click="closeDialog(0)"
          src="../../assets/image/close_icon_green.png"
        />
        <div
          class="pup_form_btn reg_step_1_send"
          :style="{backgroundColor:isAgreed?'#0ba96c':'#888888'}"
          @click="sendResgisterMessage"
        >確認送出</div>
      </div>
    </div>
    <!-- ... 註冊end ... -->
    <!-- ... 驗證 ... -->
    <div class="pup_container_form reg_step_2" v-if="registerDialog==2">
      <div class="pup_form_bg" @click="closeDialog(0)"></div>
      <div class="pup_form_container">
        <div class="pup_form_title">手機驗證與設定密碼</div>
        <div class="pup_form_scroll">
          <div class="form_input_container">
            <div class="form_input_title">會員帳號</div>
            <div class="form_input">
              <input v-model="registerData.phoneNum" readonly type="text" />
            </div>
          </div>
          <div class="form_input_container">
            <div class="form_input_title">設定密碼</div>
            <div class="form_input">
              <input type="password" placeholder v-model="registerData.password" />
            </div>
          </div>
          <div class="form_input_container">
            <div class="form_input_title">再次輸入確定密碼</div>
            <div class="form_input">
              <input type="password" placeholder v-model="rePassword" />
            </div>
            <div class="form_input_tip" v-if="errorTips">確定密碼與密碼輸入不一致</div>
          </div>
          <div class="form_input_container">
            <div class="form_input_title">簡訊驗證碼</div>
            <div class="form_input">
              <input placeholder v-model="registerData.code" type="text" />
            </div>
          </div>
          <div class="pup_form_tip">
            沒有收到簡訊？
            <a @click="getSmsCode">重新發送</a>
          </div>
        </div>
        <img
          alt
          class="close_icon"
          @click="closeDialog(0)"
          src="../../assets/image/close_icon_green.png"
        />
        <div class="pup_form_btn reg_step_2_send" @click="sendRegisterInfo">確認送出</div>
      </div>
    </div>
    <!-- ... 驗證end ... -->
    <!-- ... 註冊成功 ... -->
    <div class="pup_container_form reg_step_3" v-if="registerDialog==3">
      <div class="pup_form_bg" @click="closeDialog(0)"></div>
      <div class="pup_form_container">
        <div class="pup_form_text">註冊成功</div>
        <div class="pup_form_text_tip">享有免費學習體驗一次</div>
        <img
          alt
          class="close_icon"
          @click="closeDialog(0)"
          src="../../assets/image/close_icon_green.png"
        />
        <div class="pup_form_btn reg_step_3_send" @click="goRouterNew('login')">登入會員</div>
      </div>
    </div>
    <!-- ... 註冊成功end ... -->
    <!-- ... pup視窗-服務條款 ... -->
    <div class="notice_pup_rule sevice_rule" v-if="secretId==1">
      <div @click="secretId=0" class="notice_pup_bg"></div>
      <div class="notice_pup_container">
        <div class="notice_pup_title">服務條款</div>
        <div class="notice_pup_main" style="height:600px;"></div>
        <img @click="secretId=0" alt class="notice_pup_close" src="../../assets/image/close_icon.png" />
      </div>
    </div>
    <!-- ... pup視窗-服務條款end ... -->

    <!-- ... pup視窗-隱私權聲明 ... -->
    <div class="notice_pup_rule right_to_privacy_rule" v-if="secretId==2">
      <div class="notice_pup_bg" @click="secretId=0"></div>
      <div class="notice_pup_container">
        <div class="notice_pup_title">隱私權聲明</div>
        <div class="notice_pup_main" style="height:600px;"></div>
        <img @click="secretId=0" alt class="notice_pup_close" src="../../assets/image/close_icon.png" />
      </div>
    </div>
    <!-- ... pup視窗-隱私權聲明end ... -->
  </div>
</template>
<script>
import { getHsdUserRegster, getHsdCulum, getHsdSmsCode } from "../../api/api";
import { mapState ,mapMutations} from "vuex";
import { Message } from "element-ui";
export default {
  props: { registerDialog: 0 },
  data() {
    return {
      secretId:0,
      regTel: false, //手機格式校驗
      rePassword: "",
      isAgreed: false,
      registerData: {
        phoneNum: "",
        codeId: "",
        studentName: "",
        grade: "",
        code: "",
        password: ""
      },
      smsList: {
        countryCode: "86",
        templateCode: "1"
      },
      culumList: [],
      gradeList: [],
      errorTips: false
    };
  },
  computed: {
    ...mapState(["hsdGradeList"])
  },
  created() {
    this.getCulum();
  },
  methods: {
	  ...mapMutations(['changeTopNavId']),
    //关闭弹窗
    closeDialog(id) {
      this.$emit("changeStep", id);
    },
    //match telephone
    matchTel(e) {
      const reg = new RegExp(/^[1][3-9]\d{9}$/); //大陆
      const reg_x = new RegExp(/^([6|9])\d{7}$/); //效果
      const reg_t = new RegExp(/^[0][9]\d{8}$/); //台湾
      const reg_a = new RegExp(/^[6]([8|6])\d{5}$/); //澳门
      this.regTel = false;
      if (reg_t.test(e.target.value)) {
        this.smsList.countryCode = "886";
      } else if (reg_x.test(e.target.value)) {
        this.smsList.countryCode = "852";
      } else if (reg_a.test(e.target.value)) {
        this.smsList.countryCode = "853";
      } else if (reg.test(e.target.value)) {
        this.smsList.countryCode = "86";
      } else {
        this.regTel = true;
      }
    },
    //register message
    sendResgisterMessage() {
      if (!this.isAgreed || this.regTel) return false;
      this.getSmsCode(2);
    },
    //用户注册信息提交
    async sendRegisterInfo() {
      if (this.rePassword !== this.registerData.password) {
        this.errorTips = true;
        return;
      }
      let form = Object.assign(this.registerData, this.smsList);
      const res = await getHsdUserRegster(form);
      if (res && res.success == 1) {
        this.$emit("changeStep", 3);
      } else {
        Message({
          type: "error",
          message: res.msg
        });
      }
    },
    //获取短信验证码
    async getSmsCode(i) {
      this.smsList.phoneNum = this.registerData.phoneNum;
      //13872250190
      const res = await getHsdSmsCode(this.smsList);
      if (res && res.code == 200 && res.success == 1) {
        i && this.$emit("changeStep", 2); //触发父组件方法
        this.registerData.codeId = res.data.code_id;
      } else {
        Message({
          type: "error",
          message: res.msg
        });
      }
    },
    //获取年級科目列表
    async getCulum() {
      const res = await getHsdCulum();
      if (res && res.code == "200") {
        this.culumList = res.data;
      }
    },
    setGrade(k) {
      //  console.log(k.target.value);
    },
    //新的页面
    goRouterNew(id) {
      this.$emit("changeStep", 0);
      const router = id ? `/${id}` : "/login";
      if (window.location.pathname == router) return;
      this.$router.push(router);
      this.changeTopNavId('login')
    }
  }
};
</script>
<style scoped>
</style>