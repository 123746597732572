<template>
  <div>
    <!-- ... 內頁主內容 ... -->
    <div class="page_main">
      <div class="page_main_container">
        <div class="page_infor_btn_contianer">
          <div
            class="page_infor_btn"
            :class="{ active: showProgressId == 1 }"
            @click="showProgressId = 1"
          >
            上課流程
          </div>
          <div
            class="page_infor_btn"
            :class="{ active: showProgressId == 2 }"
            @click="showProgressId = 2"
          >
            建議設備
          </div>
          <div
            class="page_infor_btn"
            :class="{ active: showProgressId == 3 }"
            @click="showProgressId = 3"
          >
            軟體前置作業
          </div>
        </div>
        <div>
          <div v-if="showProgressId == 1">
            <div class="page_process_container">
              <div class="page_step_container">
                <div class="page_step_text_odd page_step_left">
                  <div class="page_step_text_title">
                    步驟
                    <span>1</span>
                    <div>step</div>
                  </div>
                  <div class="page_step_text_infor">線上註冊會員，初步檢測</div>
                  <div class="page_step_text_text">
                    成為會員免費進行體驗，我們會盡快與您聯繫
                  </div>
                  <div
                    class="page_step_text_btn reg_open"
                    @click="setRegisterStep(1)"
                  >
                    註冊會員
                  </div>
                </div>
                <div class="page_step_pic_odd page_step_right">
                  <picture>
                    <source
                      media="(max-width:400px)"
                      srcset="../../assets/image/page_step_pic_1_400.jpg"
                    />
                    <source
                      media="(max-width:560px)"
                      srcset="../../assets/image/page_step_pic_1_560.jpg"
                    />
                    <source
                      media="(max-width:750px)"
                      srcset="../../assets/image/page_step_pic_1_750.jpg"
                    />
                    <img src="../../assets/image/page_step_pic_1.jpg" alt />
                  </picture>
                </div>
              </div>
              <div class="page_step_container">
                <div class="page_step_pic page_step_left">
                  <picture>
                    <source
                      media="(max-width:400px)"
                      srcset="../../assets/image/page_step_pic_2_400.jpg"
                    />
                    <source
                      media="(max-width:560px)"
                      srcset="../../assets/image/page_step_pic_2_560.jpg"
                    />
                    <source
                      media="(max-width:750px)"
                      srcset="../../assets/image/page_step_pic_2_750.jpg"
                    />
                    <img src="../../assets/image/page_step_pic_2.jpg" alt />
                  </picture>
                </div>
                <div class="page_step_text page_step_right">
                  <div class="page_step_text_title">
                    步驟
                    <span>2</span>
                    <div>step</div>
                  </div>
                  <div class="page_step_text_infor">進行剖析訂製學習計畫</div>
                  <div class="page_step_text_text">
                    學習規劃師透過檢測分析學生的強弱項目，安排學習計畫
                  </div>
                  <div class="page_step_text_item_container">
                    <div class="page_step_text_item">
                      <img src="../../assets/image/page_step_item1.png" alt />
                      <span>確認上課時間</span>
                    </div>
                    <div class="page_step_text_item">
                      <img src="../../assets/image/page_step_item2.png" alt />
                      <span>學習規劃排課</span>
                    </div>
                    <div class="page_step_text_item">
                      <img src="../../assets/image/page_step_item3.png" alt />
                      <span>開始進行課程</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="page_step_container">
                <div class="page_step_text_odd page_step_left">
                  <div class="page_step_text_title">
                    步驟
                    <span>3</span>
                    <div>step</div>
                  </div>
                  <div class="page_step_text_infor">進入線上教室開始上課</div>
                  <div class="page_step_text_text">
                    在家即可與老師一對一進行學習
                  </div>
                  <div class="page_step_text_item_container">
                    <div class="page_step_text_item">
                      <img src="../../assets/image/page_step_item4.png" alt />
                      <span>面對面互動學習</span>
                    </div>
                    <div class="page_step_text_item">
                      <img src="../../assets/image/page_step_item5.png" alt />
                      <span>課業問題即時解答</span>
                    </div>
                    <div class="page_step_text_item">
                      <img src="../../assets/image/page_step_item6.png" alt />
                      <span>學習分析重點加強</span>
                    </div>
                  </div>
                </div>
                <div class="page_step_pic_odd page_step_right">
                  <picture>
                    <source
                      media="(max-width:400px)"
                      srcset="../../assets/image/page_step_pic_3_400.jpg"
                    />
                    <source
                      media="(max-width:560px)"
                      srcset="../../assets/image/page_step_pic_3_560.jpg"
                    />
                    <source
                      media="(max-width:750px)"
                      srcset="../../assets/image/page_step_pic_3_750.jpg"
                    />
                    <img src="../../assets/image/page_step_pic_3.jpg" alt />
                  </picture>
                </div>
              </div>
            </div>
            <div class="page_process_mobile_container">
              <div class="page_process_title">課後報告即時回應</div>
              <div class="page_process_mobile">
                <div class="page_process_mobile_pic">
                  <img src="../../assets/image/page_process_mobile_pic1.jpg" alt />
                </div>
                <div class="page_process_mobile_title">課後即時回報</div>
                <div class="page_process_mobile_text">
                  匯總每堂課上課情況，讓家長即時了解上課情形，課堂效果更透明。
                </div>
              </div>
              <div class="page_process_mobile">
                <div class="page_process_mobile_pic">
                  <img src="../../assets/image/page_process_mobile_pic2.jpg" alt />
                </div>
                <div class="page_process_mobile_title">客製化指派作業</div>
                <div class="page_process_mobile_text">
                  依照學生上課情況、學科能力、錯因佔比，精準定位學生的學習狀況，訂製客製化作業。
                </div>
              </div>
              <div class="page_process_mobile">
                <div class="page_process_mobile_pic">
                  <img src="../../assets/image/page_process_mobile_pic3.jpg" alt />
                </div>
                <div class="page_process_mobile_title">作業批改並即時反饋</div>
                <div class="page_process_mobile_text">
                  即時反饋作業批改結果及錯因分析，幫助學生認清知識點掌握情況，鞏固練習。
                </div>
              </div>
            </div>
          </div>
          <div v-if="showProgressId == 2">
            <div class="page_device">
              <div class="page_device_container">
                <div class="page_device_title" style="display: none">
                  設備資訊
                </div>
                <div class="page_device_pic device_pic_left">
                  <img src="../../assets/image/page_device_pic1.jpg" alt />
                  <div class="page_device_pic_text">
                    桌上型電腦＋視訊鏡頭＋耳機麥克風
                  </div>
                </div>
                <div class="page_device_pic device_pic_right">
                  <img src="../../assets/image/page_device_pic2.jpg" alt />
                  <div class="page_device_pic_text">
                    筆記型電腦(內置或外接視訊鏡頭)＋耳機麥克風
                  </div>
                </div>
              </div>
              <div class="page_device_form">
                <div class="page_device_form_row">
                  <div class="page_device_form_th">CPU</div>
                  <div class="page_device_form_td">
                    Intel Core i3 3.9 Ghz CPU 以上
                  </div>
                </div>
                <div class="page_device_form_row">
                  <div class="page_device_form_th">作業系統</div>
                  <div class="page_device_form_td">
                    微軟Windows 7 、Windows8、Windows8.1、Windows10的作業系統
                  </div>
                </div>
                <div class="page_device_form_row">
                  <div class="page_device_form_th">硬碟空間</div>
                  <div class="page_device_form_td">
                    電腦需 4 GB 以上的記憶體 (RAM) / 10 GB以上可用硬碟空間
                  </div>
                </div>
                <div class="page_device_form_row">
                  <div class="page_device_form_th">顯示器</div>
                  <div class="page_device_form_td">
                    顯示至少16bit色和1024x768解析度的顯示器與顯示卡
                  </div>
                </div>
                <div class="page_device_form_row">
                  <div class="page_device_form_th">耳機/麥克風</div>
                  <div class="page_device_form_td">
                    一體成型頭掛式雙接頭耳機/麥克風
                  </div>
                </div>
                <div class="page_device_form_row">
                  <div
                    class="page_device_form_th"
                    style="border-bottom: 1px solid #137a52"
                  >
                    網路需求
                  </div>
                  <div
                    class="page_device_form_td"
                    style="border-bottom: 1px solid #757575"
                  >
                    建議以有線寬頻連接網際網路－網路頻寬需求為專線光纖16M/8M以上或涵蓋率良好4G手機網路，盡可能避免使用Cable或第四台網路(因非專線連線方式，可能於熱門使用時段有頻寬不穩情況)。
                  </div>
                </div>
              </div>
              <div class="page_device_container">
                <div class="page_device_title">參考設備</div>
                <div class="page_device_pic device_pic_left">
                  <img src="../../assets/image/page_device_pic3.jpg" alt />
                  <div class="page_device_pic_text">羅技(Logitech)H151</div>
                </div>
                <div class="page_device_pic device_pic_right">
                  <img src="../../assets/image/page_device_pic4.jpg" alt />
                  <div class="page_device_pic_text">羅技(Logitech)C270</div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showProgressId == 3">
            <div class="page_setting_container">
              <div class="page_setting_row">
                <div
                  @click="dialogPictrueId = 1"
                  class="page_setting_btn setting_btn_green"
                >
                  Zoom下載安裝
                  <br />圖文說明
                </div>
                <!--
                <div @click="dialogPictrueId=2" class="page_setting_btn setting_btn_green">
                  iTeacher課前設定
                  <br />圖文說明
                </div>
                <div @click="dialogPictrueId=3" class="page_setting_btn setting_btn_green">
                  iTutor課前設定
                  <br />圖文說明
                </div>
                -->
                <div
                  @click="dialogPictrueId = 4"
                  class="page_setting_btn setting_btn_yellow"
                >
                  zoom下載安裝
                  <br />示範影片
                </div>
                <!--
                <div @click="dialogPictrueId=5" class="page_setting_btn setting_btn_yellow">
                  iTeacher課前設定
                  <br />示範影片
                </div>
                -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 注册弹窗 -->
    <register-dialog
      :registerDialog="registerStep"
      @changeStep="setRegisterStep"
    ></register-dialog>
    <!-- 注册弹窗 end -->
    <!-- ... 內頁主內容end ... -->
    <!-- ... zoom下載安裝-圖文說明 ... -->
    <div class="zoom_setting" v-if="dialogPictrueId == 1">
      <div @click="dialogPictrueId = 0" class="pup_form_bg"></div>
      <div class="setting_pup_container">
        <div class="setting_pup_title">Zoom下載安裝說明</div>

        <div class="setting_detail_container">
          <div class="setting_step_container">
            <div class="setting_step_text">
              步驟1：前往Zoom(視訊軟體)網址下載
            </div>
            <div class="setting_step_text">
              <a
                href="https://zoomnow.net/zntw_zoom_download.php?showType=ALL"
                target="_blank"
                >點此至下載網址</a
              >
            </div>
          </div>
          <div class="setting_step_container">
            <div>步驟2：選擇您通訊載具系統版本點擊下載</div>
            <div class="setting_step_pic">
              <img src="../../assets/image/setting_pic/zoom_setting_1.jpg" alt />
            </div>
            <div class="setting_step_tip" style="color: #ff0000">
              使用手機、平板上課時會可能有文字過小無法清晰閱讀以及功能受限的狀況，建議使用桌上型電腦或筆記型電腦進行上課。
            </div>
          </div>
          <div class="setting_step_infor">
            接續安裝步驟為window電腦版本，其他系統安裝檔案流程可依系統提示依序進行，如有疑問可網路搜尋相關教學或聯絡客服人員。
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">步驟3：儲存zoom安裝檔</div>
            <div class="setting_step_pic">
              <img src="../../assets/image/setting_pic/zoom_setting_2.jpg" alt />
            </div>
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">
              步驟4：安裝檔下載完成後，點擊安裝檔進行安裝
            </div>
            <div class="setting_step_pic">
              <img src="../../assets/image/setting_pic/zoom_setting_3.jpg" alt />
            </div>
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">步驟5：點選「是」執行zoom安裝檔</div>
            <div class="setting_step_pic">
              <img src="../../assets/image/setting_pic/zoom_setting_4.jpg" alt />
            </div>
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">
              步驟6：點選「是」允許zoom應用程式
            </div>
            <div class="setting_step_pic">
              <img src="../../assets/image/setting_pic/zoom_setting_5.jpg" alt />
            </div>
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">步驟7：等候安裝進度完成</div>
            <div class="setting_step_pic">
              <img src="../../assets/image/setting_pic/zoom_setting_6.jpg" alt />
            </div>
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">
              步驟8：安裝完成後可在工作列中滑鼠右鍵點擊zoom圖示，選擇釘選至工作列，以便下次開啟使用
            </div>
            <div class="setting_step_pic">
              <img src="../../assets/image/setting_pic/zoom_setting_7.jpg" alt />
            </div>
          </div>
        </div>
        <img
          alt
          @click="dialogPictrueId = 0"
          class="member_pup_close"
          src="../../assets/image/close_icon.png"
        />
      </div>
    </div>

    <!-- ... iteacher設定-圖文說明 ... -->
    <div class="iteacher_setting" v-if="dialogPictrueId == 2">
      <div @click="dialogPictrueId = 0" class="pup_form_bg"></div>
      <div class="setting_pup_container">
        <div class="setting_pup_title">iTeacher課前設定說明</div>

        <div class="setting_detail_container">
          <div class="setting_step_infor">
            以下步驟為window電腦版本，其他系統之設定流程可依系統提示依序進行，如有疑問可網路搜尋相關教學或聯絡客服人員。
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">步驟1：開啟zoom軟體</div>
            <div class="setting_step_pic">
              <img
                src="../../assets/image/setting_pic/iteacher_setting_1.jpg"
                alt
              />
            </div>
            <div class="setting_step_pic">
              <img
                src="../../assets/image/setting_pic/iteacher_setting_1-2.jpg"
                alt
              />
            </div>
            <div class="setting_step_tip">
              如果找不到zoom圖示開啟軟體，可點擊window開始鍵，在搜尋欄輸入「zoom」進行搜尋
            </div>
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">步驟2：點選「登入」</div>
            <div class="setting_step_pic">
              <img
                src="../../assets/image/setting_pic/iteacher_setting_2.jpg"
                alt
              />
            </div>
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">步驟3：選擇登入方式進行登入</div>
            <div class="setting_step_pic">
              <img
                src="../../assets/image/setting_pic/iteacher_setting_3.jpg"
                alt
              />
            </div>
            <div class="setting_step_tip">
              如果沒有任何帳號，可點選「免費註冊」依引導之步驟提示完成zoom帳號註冊
            </div>
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">
              步驟4：登入後畫面，點擊「齒輪圖示」進行設定
            </div>
            <div class="setting_step_pic">
              <img
                src="../../assets/image/setting_pic/iteacher_setting_4.jpg"
                alt
              />
            </div>
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">
              步驟5：開啟設定畫面，點選「分享畫面」，勾選「遠程控制時，允許對方控制所有的應用程序」
            </div>
            <div class="setting_step_pic">
              <img
                src="../../assets/image/setting_pic/iteacher_setting_5.jpg"
                alt
              />
            </div>
            <div class="setting_step_tip">
              此為方便老師上課時進行滑鼠、鍵盤之操作，不會有影響電腦安全之疑慮。
            </div>
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">
              步驟6：關閉設定畫面，回到主畫面，點選擊上角圖示開啟視窗，點選「登出」
            </div>
            <div class="setting_step_pic">
              <img
                src="../../assets/image/setting_pic/iteacher_setting_6.jpg"
                alt
              />
            </div>
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">步驟7：點擊「返回」</div>
            <div class="setting_step_pic">
              <img
                src="../../assets/image/setting_pic/iteacher_setting_7.jpg"
                alt
              />
            </div>
          </div>
          <div class="setting_step_infor">
            步驟至此上課前置設定已完成，後續為開始進行上課設定之步驟
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">步驟8：點選「加入會議」</div>
            <div class="setting_step_pic">
              <img
                src="../../assets/image/setting_pic/iteacher_setting_8.jpg"
                alt
              />
            </div>
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">
              步驟9：輸入老師提供的會議ID共十碼，並點擊「加入會議」
            </div>
            <div class="setting_step_pic">
              <img
                src="../../assets/image/setting_pic/iteacher_setting_9.jpg"
                alt
              />
            </div>
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">步驟10：點選「加入並開啟視訊」</div>
            <div class="setting_step_pic">
              <img
                src="../../assets/image/setting_pic/iteacher_setting_10.png"
                alt
              />
            </div>
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">
              步驟11：點選「使用電腦語音設備」
            </div>
            <div class="setting_step_pic">
              <img
                src="../../assets/image/setting_pic/iteacher_setting_11.png"
                alt
              />
            </div>
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">
              步驟12：點選下方列表的「共享螢幕」
            </div>
            <div class="setting_step_pic">
              <img
                src="../../assets/image/setting_pic/iteacher_setting_12.jpg"
                alt
              />
            </div>
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">
              步驟13：點選「螢幕」，勾選「共享電腦聲音」，點擊「共享螢幕」
            </div>
            <div class="setting_step_pic">
              <img
                src="../../assets/image/setting_pic/iteacher_setting_13.png"
                alt
              />
            </div>
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">
              步驟14：將滑鼠移至頂端，出現工作列後，點選「遠端控制」並且點選滑鼠/鍵盤控制權給:「老師」
            </div>
            <div class="setting_step_pic">
              <img
                src="../../assets/image/setting_pic/iteacher_setting_14.png"
                alt
              />
            </div>
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">
              步驟15：頂端工具列出現老師正在控制您的螢幕，即完成上課所需設定
            </div>
            <div class="setting_step_pic">
              <img
                src="../../assets/image/setting_pic/iteacher_setting_15.jpg"
                alt
              />
            </div>
          </div>
        </div>
        <img
          alt
          @click="dialogPictrueId = 0"
          class="member_pup_close"
          src="../../assets/image/close_icon.png"
        />
      </div>
    </div>

    <!-- ... itutor設定-圖文說明 ... -->
    <div class="itutor_setting" v-if="dialogPictrueId == 3">
      <div @click="dialogPictrueId = 0" class="pup_form_bg"></div>
      <div class="setting_pup_container">
        <div class="setting_pup_title">iTutor課前設定說明</div>

        <div class="setting_detail_container">
          <div class="setting_step_infor">
            以下步驟為window電腦版本，其他系統之設定流程可依系統提示依序進行，如有疑問可網路搜尋相關教學或聯絡客服人員。
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">步驟1：開啟zoom軟體</div>
            <div class="setting_step_pic">
              <img
                src="../../assets/image/setting_pic/iteacher_setting_1.jpg"
                alt
              />
            </div>
            <div class="setting_step_pic">
              <img
                src="../../assets/image/setting_pic/iteacher_setting_1-2.jpg"
                alt
              />
            </div>
            <div class="setting_step_tip">
              如果找不到zoom圖示開啟軟體，可點擊window開始鍵，在搜尋欄輸入「zoom」進行搜尋
            </div>
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">步驟2：點選「加入會議」</div>
            <div class="setting_step_pic">
              <img
                src="../../assets/image/setting_pic/iteacher_setting_8.jpg"
                alt
              />
            </div>
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">
              步驟3：輸入老師提供的會議ID共十碼，並點擊「加入會議」
            </div>
            <div class="setting_step_pic">
              <img
                src="../../assets/image/setting_pic/iteacher_setting_9.jpg"
                alt
              />
            </div>
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">步驟4：點選「加入並開啟視訊」</div>
            <div class="setting_step_pic">
              <img
                src="../../assets/image/setting_pic/iteacher_setting_10.png"
                alt
              />
            </div>
          </div>
          <div class="setting_step_container">
            <div class="setting_step_text">
              步驟5：點選「使用電腦語音設備」,開始進行上課
            </div>
            <div class="setting_step_pic">
              <img
                src="../../assets/image/setting_pic/iteacher_setting_11.png"
                alt
              />
            </div>
          </div>
        </div>
        <img
          alt
          @click="dialogPictrueId = 0"
          class="member_pup_close"
          src="../../assets/image/close_icon.png"
        />
      </div>
    </div>
    <!-- ... zoom下載安裝-影音播放 ... -->
    <div
      class="setting_video_play_container zoom_video_play"
      v-if="dialogPictrueId == 4"
    >
      <div class="setting_video_play_bg"></div>
      <div class="setting_video_play">
        <img
          @click="dialogPictrueId = 0"
          src="../../assets/image/pup_video_close.png"
          class="setting_video_close"
          alt
        />
        <img
          src="../../assets/image/video_cover.jpg"
          class="setting_video_cover"
          alt
        />
        <div class="setting_video_play_iframe">
          <iframe
            src="https://www.youtube.com/embed/WvHiF6vfMcA"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <!-- ... iteacher課前設定-影音播放 ... -->
    <div
      class="setting_video_play_container iteacher_video_play"
      v-if="dialogPictrueId == 5"
    >
      <div class="setting_video_play_bg"></div>
      <div class="setting_video_play">
        <img
          @click="dialogPictrueId = 0"
          src="../../assets/image/pup_video_close.png"
          class="setting_video_close"
          alt
        />
        <img
          src="../../assets/image/video_cover.jpg"
          class="setting_video_cover"
          alt
        />
        <div class="setting_video_play_iframe"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import registerDialog from "../childComponent/registerDialog";
export default {
  data() {
    return { registerStep: 0, showProgressId: 1, dialogPictrueId: 0 };
  },
  components: { registerDialog },
  created() {
    console.log(this.showProgressId);
  },
  methods: {
    ...mapMutations(["changeTopNavId"]),
    setRegisterStep(id) {
      this.registerStep = id;
    },
  },
};
</script>
<style scoped>
</style>